import React from 'react'

export const LeftArrowIcon = ({ width = 6 }) => (
  <svg
    style={{ width }}
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="svg-inline--fa fa-angle-left fa-w-8 fa-3x"
    data-icon="angle-left"
    data-prefix="fas"
    viewBox="0 0 256 512"
  >
    <path
      fill="currentColor"
      d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
    ></path>
  </svg>
)

export const UserIcon = ({ width = 12 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="svg-inline--fa fa-user fa-w-14 fa-3x"
    data-icon="user"
    data-prefix="far"
    viewBox="0 0 448 512"
    style={{ width }}
  >
    <path
      fill="currentColor"
      d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"
    ></path>
  </svg>
)

export const CalendarIcon = ({ width = 12 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="svg-inline--fa fa-calendar-alt fa-w-14 fa-3x"
    data-icon="calendar-alt"
    data-prefix="far"
    viewBox="0 0 448 512"
    style={{ width }}
  >
    <path
      fill="currentColor"
      d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"
    ></path>
  </svg>
)

export const ClockIcon = ({ width = 12 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="svg-inline--fa fa-clock fa-w-16 fa-3x"
    data-icon="clock"
    data-prefix="far"
    viewBox="0 0 512 512"
    style={{ width }}
  >
    <path
      fill="currentColor"
      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
    ></path>
  </svg>
)

export const QuoteIcon = ({ width = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="19"
    fill="none"
    viewBox="0 0 24 19"
    style={{ width }}
  >
    <path
      fill="#0D0F11"
      fillOpacity="0.15"
      d="M4.26 18.48c-.88 0-1.62-.2-2.22-.6-.56-.4-1.06-.82-1.5-1.26-.16-.44-.3-.92-.42-1.44-.08-.52-.12-1.06-.12-1.62 0-2.04.44-3.84 1.32-5.4.92-1.6 2.12-3.04 3.6-4.32C6.4 2.52 8.02 1.24 9.78 0l1.68 1.92c-1.2 1-2.26 2-3.18 3-.88.96-1.72 2-2.52 3.12l1.8 1.92c.48.52.86 1.12 1.14 1.8.32.64.48 1.32.48 2.04 0 1.36-.5 2.48-1.5 3.36-.96.88-2.1 1.32-3.42 1.32zm12.42 0c-.88 0-1.62-.2-2.22-.6-.56-.4-1.06-.82-1.5-1.26-.16-.44-.3-.92-.42-1.44-.08-.52-.12-1.06-.12-1.62 0-2.04.44-3.84 1.32-5.4.92-1.6 2.12-3.04 3.6-4.32 1.48-1.32 3.1-2.6 4.86-3.84l1.68 1.92c-1.2 1-2.26 2-3.18 3-.88.96-1.72 2-2.52 3.12l1.8 1.92c.48.52.86 1.12 1.14 1.8.32.64.48 1.32.48 2.04 0 1.36-.5 2.48-1.5 3.36-.96.88-2.1 1.32-3.42 1.32z"
    ></path>
  </svg>
)

export const LinkedinIcon = ({ width = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    ariaHidden="true"
    className="svg-inline--fa fa-linkedin fa-w-14 fa-3x"
    data-icon="linkedin"
    data-prefix="fab"
    viewBox="0 0 448 512"
    style={{ width }}
  >
    <path
      fill="currentColor"
      d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
    ></path>
  </svg>
)

export const GlobalIcon = ({ width = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    ariaHidden="true"
    className="svg-inline--fa fa-globe fa-w-16 fa-3x"
    data-icon="globe"
    data-prefix="fas"
    viewBox="0 0 496 512"
    style={{ width }}
  >
    <path
      fill="currentColor"
      d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"
    ></path>
  </svg>
)

export const TelegramIcon = ({ width = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    ariaHidden="true"
    className="svg-inline--fa fa-telegram fa-w-16 fa-3x"
    data-icon="telegram"
    data-prefix="fab"
    viewBox="0 0 496 512"
    style={{ width }}
  >
    <path
      fill="currentColor"
      d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"
    ></path>
  </svg>
)